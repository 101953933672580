@import '~bootstrap/scss/functions';

$spacers: (
  0: 0,
  1: 0.5rem,
  2: 1rem,
  3: 1.5rem,
  4: 2rem,
  5: 2.5rem,
);

.modal.slide-in-bottom .modal-dialog {
  transition: transform 0.3s ease-in;
  transform: translate(0, 100dvh);
}

@import '~bootstrap/scss/bootstrap';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {      border-top: $border-width solid $border-color !important; }
    .border#{$infix}-right {    border-right: $border-width solid $border-color !important; }
    .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color !important; }
    .border#{$infix}-left {     border-left: $border-width solid $border-color !important; }

    .border#{$infix}-top-0 {    border-top: none !important; }
    .border#{$infix}-right-0 {  border-right: none !important; }
    .border#{$infix}-bottom-0 { border-bottom: none !important; }
    .border#{$infix}-left-0 {   border-left: none !important; }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }

    .border#{$infix}-bottom-none { border-bottom: none !important }

    .position#{$infix}-sticky { position: sticky; }

    .w#{$infix}-auto { width: auto !important; }

    .w#{$infix}-50 { width: 50% !important; }
  }
}

:root {
  --bs-white-rgb: 247, 247, 248;
  --bs-danger-rgb: 163, 67, 71;
  --bs-heading-rgb: 34, 54, 57;
  --bs-icon-rgb: 173, 175, 175;
  --bs-heading-color: rgba(var(--bs-heading-rgb), 1);
  --bs-success-rgb: 98, 155, 90;
  --body-text-color: rgba(49, 70, 72, 0.7);
  --comment-text-color: rgba(75, 110, 115, 0.6);
  --bg-color: #EEEFEF;
  --bg-grey-color: #EAEAEA;
  --footer-color: #E0E4E4;
  --accent-color: #009BAF;
  --stroke-primary-color: rgba(125, 142, 145, 0.4);
  --stroke-secondary-color: rgba(186, 190, 191, 0.4);
  --error-bg-color: #F0E0E0;
  --error-color: rgba(var(--bs-danger-rgb), 1);
  --success-color: #629B5A;
  --system-white-color: rgba(var(--bs-white-rgb), 1);

  --bs-body-bg: var(--bg-color);
  --bs-border-color: var(--stroke-primary-color);
  --bs-primary-bg-subtle: var(--bg-color) ;
  --bs-tertiary-bg: #E2E6E6;
  --bs-link-color: #07626F;
  --bs-link-hover-color: #07626F;
  --bs-border-radius-sm: var(--bs-border-radius);
  --stroke-color: rgba(24, 33, 30, 0.7);


  --bs-border-radius: 0.875rem;
}

#navbar-spacer {
  height: 40px;
}

body {
  font-family: 'DM Sans', sans-serif;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  color: var(--body-text-color);
}

.mw-750 {
  max-width: 750px;
}

/* Text styles */
h1,
.h1 {
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4rem;
  letter-spacing: -0.1875rem;
}

h2,
.h2 {
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.125rem;
  letter-spacing: -0.125rem;
}

h3,
.h3 {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem;
  letter-spacing: -0.0625rem;
}

h4,
.h4 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.125rem;
}

h5,
.h5 {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
}

.text-small {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
}

path {
  stroke: rgba(var(--bs-icon-rgb), var(--bs-text-opacity, 1))
}

.text-body {
  color: rgba(var(--bs-heading-rgb), var(--bs-text-opacity, 1));
}

.text-body path {
  stroke: rgba(var(--bs-heading-rgb), var(--bs-text-opacity, 1));
}

.text-muted {
  color: var(--comment-text-color);
}

.text-muted path {
  stroke: var(--comment-text-color);
}

.text-success {
  color: var(--success-color)!important;
}

.text-success path {
  stroke: var(--success-color)!important;
}

.text-danger path {
  stroke: rgba(var(--bs-danger-rgb), var(--bs-text-opacity, 1))!important;
}

.text-accent {
  color: #07626F !important;
}

.text-accent path {
  stroke: #07626F !important;
}

/* -------- */


/* Button styles */
.btn {
  display: inline-flex;
  /* border: none; */
  height: 2.5rem;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  background-clip: padding-box
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--accent-color);
  --bs-btn-border-color: var(--accent-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #197A87;
  --bs-btn-hover-border-color: #197A87;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #07626F;
  --bs-btn-disabled-color: rgba(255, 255, 255, 0.52);
  --bs-btn-disabled-bg: #85B5BC;
  --bs-btn-disabled-border-color: #85B5BC;
}

.btn-primary:focus {
  box-shadow: 0px 0px 24px 0px rgba(37, 192, 212, 0.40);
}

.btn-secondary {
  --bs-btn-color: #07626F;
  --bs-btn-bg: rgba(0, 155, 175, 0.2);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #07626F;
  --bs-btn-hover-bg: #A7DFE7;
  --bs-btn-hover-border-color: #A7DFE7;
  --bs-btn-active-color: #07626F;
  --bs-btn-active-bg: #95D7E0;
  --bs-btn-active-border-color: #95D7E0;

  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
}

.btn-info {
  --bs-btn-color: #07626F;
  --bs-btn-bg: #DEE5E6;
  --bs-btn-border-color: #DEE5E6;
  --bs-btn-hover-color: #07626F;
  --bs-btn-hover-bg: #CAE2E5;
  --bs-btn-hover-border-color: #CAE2E5;
  --bs-btn-active-color: #07626F;
  --bs-btn-active-bg: #C5E5EB;
  --bs-btn-active-border-color: #C5E5EB;
  --bs-btn-disabled-border-color: var(--bs-btn-border-color);
  --bs-btn-disabled-color: var(--bs-btn-color) 50%;
  --bs-btn-disabled-bg: var(--bs-btn-bg) 50%;
}

.btn-light {
  --bs-btn-color: #07626F;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--stroke-primary-color);
  --bs-btn-hover-color: #07626F;
  --bs-btn-hover-bg: rgba(0, 155, 175, 0.1);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #07626F;
  --bs-btn-active-bg: rgba(0, 155, 175, 0.2);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: var(--bs-heading-color);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(34, 54, 57, 0.15);
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--error-color);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #983E42;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #85383B;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: rgba(250, 250, 250, 0.5);
  --bs-btn-disabled-bg: #B37577;
  --bs-btn-disabled-border-color: transparent;
}

.btn-ghost {
  --bs-btn-color: #07626F;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #07626F;
  --bs-btn-hover-bg: rgba(0, 155, 175, 0.1);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #07626F;
  --bs-btn-active-bg: rgba(0, 155, 175, 0.2);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: var(--bs-heading-color);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(34, 54, 57, 0.15);
}

.btn-ghost-neutral {
  --bs-btn-color: var(--body-text-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--body-text-color);
  --bs-btn-hover-bg: rgba(186, 190, 191, 0.2);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--body-text-color);
  --bs-btn-active-bg: var(--stroke-secondary-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: var(--bs-heading-color);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(34, 54, 57, 0.15);
}

.btn-logout {
  --bs-btn-color: var(--body-text-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--error-color);
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--error-color);
  --bs-btn-active-bg: var(--error-bg-color);
  --bs-btn-active-border-color: transparent;
  width: fit-content;
}

.btn-input {
  --bs-btn-color: var(--bs-heading-color);
  --bs-btn-bg: var(--system-white-color);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-btn-color);
  --bs-btn-hover-bg: var(--bs-btn-bg);
  --bs-btn-hover-border-color: var(--bs-btn-border-color);
  --bs-btn-active-color: var(--bs-btn-color);
  --bs-btn-active-bg: var(--bs-btn-bg);
  --bs-btn-active-border-color: var(--bs-btn-border-color);
}

.btn-light:disabled,
.btn-ghost:disabled {
  opacity: 40%;
}

.btn-group .btn-ghost.disabled {
  border: none;
}

.btn-icon-wrapper {
  --bs-btn-color: rgba(173, 175, 175, 1);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: rgba(143, 153, 153, 1);
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: rgba(7, 98, 111, 1);
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  width: fit-content;
  // padding: 0 0.3rem;
}

.close {
  padding: 5px;
  width: 25px;
  height: 25px;
  border-radius: 1rem;
}

@media (hover: none) {
  .btn:hover {
    --bs-btn-hover-bg: var(--bs-btn-bg);
    --bs-btn-hover-color: var(--bs-btn-color);
    --bs-btn-hover-border-color: var(--bs-btn-border-color);
  }
  .btn:active {
    --bs-btn-hover-bg: var(--bs-btn-bg);
    --bs-btn-hover-color: var(--bs-btn-color);
    --bs-btn-hover-border-color: var(--bs-btn-border-color);
  }
}

svg {
  background-color: transparent;
}

.btn path {
  stroke: var(--bs-btn-color)
}

.btn:hover path {
  stroke: var(--bs-btn-hover-color);
}

.btn:active path {
  stroke: var(--bs-btn-active-color);
}

/* Alert style overrides */
.alert-heading {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
}

.alert-light {
  background-color: var(--system-white-color);
}

.alert-danger {
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.08);
  border: none;
  background-color: var(--error-bg-color);
}

.alert-error path {
  stroke: var(--error-color);
}

/* ------- */

p {
  margin-bottom: 0.5rem;
}


/* Form control style overrides */
.form-control {
  background-color: var(--system-white-color);
  border-color: transparent;
}

.profile-field-form .form-control {
  border-color: var(--stroke-primary-color);
}

.form-control:focus {
  background-color: var(--system-white-color);
  border-color: var(--accent-color);
  box-shadow: none;
}

.form-switch .form-check-input:focus {
  border-color: transparent;
}

.form-check-input:focus {
  border-color: var(--bs-border-color);
  box-shadow: none;
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28238, 238, 239, 1%29%27/%3e%3c/svg%3e");
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--error-color);
  background-image: none;
}

.invalid-feedback {
  color: var(--error-color);
}

.form-control.password-field, .form-control[id='search'] {
  display: inline-block;
}

.form-control.password-field+span, .form-control[id='search']+span {
  margin-left: calc(var(--bs-gutter-x) * -1.5);
  cursor: pointer;
}

.input-group-text {
  background-color: var(--system-white-color);
  border-color: var(--bg-color);
  border-left: none;
}

.input-group .btn.dropdown-toggle {
  background-color: var(--system-white-color);
  border-color: var(--bg-color);
}

#signup-form .input-group .btn.dropdown-toggle {
  background-color: unset;
  border-right: none;
}

.input-group .btn.dropdown-toggle:nth-child(2) {
  border-left-color: var(--stroke-primary-color);
}

.input-group .btn.dropdown-toggle:first-child {
  border-right-color: var(--stroke-primary-color);
}

div.form-switch {
  padding-left: 0 !important;
}

.form-switch .form-check-input {
  float: right;
  width: 3rem;
  height: 1.5rem;
  border-radius: var(--bs-border-radius);
  background-color: rgba(130, 166, 171, 1);
  // rgba(238, 239, 239, 1)
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28238, 238, 239, 1%29%27/%3e%3c/svg%3e");
}

.form-check-input[type='switch'] {
  border: none;
}

.form-switch .form-check-input:checked {
  background-color: rgba(25, 122, 135, 1);
}

.form-check-input:checked {
  background-color: var(--stroke-color);
  border: none;
}


#home {
  min-height: 100svh;
}

.list-group {
  --bs-list-group-border-color: var(--stroke-secondary-color);
}

#tournament-selection-form .list-group-item {
  background-color: inherit;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

/* Navigation styles */
#layout {
  min-height: 100dvh;
}

#sidebar-wrapper .nav,
.navbar-nav {
  height: 100dvh;
}

#sidebar-wrapper {
  border-right: 1px solid var(--stroke-primary-color);
  background-color: var(--bg-grey-color);
}

#sidebar-wrapper .nav-link {
  color: var(--body-text-color);
  height: 2.5rem;
}

#sidebar-wrapper .nav-link.active {
  color: #07626F;
  border-right: 2px solid #07626F;
}

#sidebar-wrapper .nav-link svg:first-of-type path {
  stroke: var(--body-text-color);
}

#sidebar-wrapper .nav-link.active svg:first-of-type path {
  stroke: #07626F;
}

.navbar {
  background-color: var(--bs-body-bg);
  align-items: start;
}

#navbar-spacer {
  height: 40px;
}

/* --------- */

/* Input group button style overrides */
.input-group .btn-input {
  --bs-btn-border-color: transparent;
  --bs-btn-disabled-color: rgba(var(--bs-heading-rgb), 0.4);
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-disabled-bg: var(--system-white-color);
  --bs-btn-hover-color: rgba(7, 98, 111, 1);
  --bs-btn-disabled-hover-border-color: transparent;
  --bs-btn-hover-bg: rgba(0, 155, 175, 0.1);
  --bs-btn-active-color: rgba(7, 98, 111, 1);
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-bg: rgba(0, 155, 175, 0.2);
}

.input-group .btn-input-bordered {
  --bs-btn-border-color: var(--stroke-primary-color);
  --bs-btn-disabled-color: rgba(var(--bs-heading-rgb), 0.4);
  --bs-btn-disabled-border-color: var(--stroke-primary-color);
  --bs-btn-disabled-bg: var(--system-white-color);
  --bs-btn-hover-color: rgba(7, 98, 111, 1);
  --bs-btn-hover-border-color: var(--stroke-primary-color);
  --bs-btn-hover-bg: rgba(0, 155, 175, 0.1);
  --bs-btn-active-color: rgba(7, 98, 111, 1);
  --bs-btn-active-border-color: var(--accent-color);
  --bs-btn-active-bg: rgba(0, 155, 175, 0.2);
}

.input-group .btn:disabled {
  opacity: 1;
}

.stepper-input.input-group .btn:nth-child(2) {
  border-left: none;
  border-right: none;
}

.stepper-input.input-group .btn:first-child {
  border-right: none;
}

.stepper-input.input-group .btn:last-child {
  border-left: none;
}

/* ------- */

/* Modal style overrides */
.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
  justify-content: space-around;
}

.modal-footer .btn {
  flex-grow: 1
}
/* ---------- */

/* Accordion style overrides */
.accordion-button:not(.collapsed) {
  box-shadow: none;
}
/* ------ */

/* Pagination style overrides */
.pagination {
  gap: 1rem;
}

.page-item .page-link {
  border: none;
  border-radius: 0.5rem;
  color: #07626F;
  background-color: #DEE5E6;
}

.page-item.disabled .page-link {
  border: 1px solid var(--stroke-primary-color);
  color: rgba(7, 98, 111, 0.4);
}

.page-item.active .page-link {
  border: 1px solid var(--accent-color);
  background-color: #C5E5EB;
}

.page-item:first-child .page-link {
  border-radius: 0.5rem;
}

.page-item:last-child .page-link {
  border-radius: 0.5rem;
}
/* ------ */

/* Common table styles */
table {
  --bs-border-color: var(--stroke-secondary-color);
}

.table-striped {
  --bs-table-striped-bg: var(--bg-grey-color);
}

table th {
  background-color: var(--bg-grey-color) !important;
  color: var(--comment-text-color);
  font-weight: normal;
}

table th,
table td {
  padding: 1rem 1rem !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-color);
  --bs-table-bg-type: var(--bs-table-bg);
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-hover > tbody > tr {
  cursor: pointer;
}
/* ------- */

/* Order list table styles */
.order-list tr {
  cursor: pointer;
}


.order-list th:first-child,
.order-list td:first-child {
  padding-left: 1.5rem
}

.order-list th:last-child,
.order-list td:last-child {
  padding-right: 1.5rem
}

/* ------- */

.user-icon {
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}

.user-icon-md {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
}

.user-icon-sm {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
}

.user-icon-lg {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 3.5rem;
  font-size: 2rem;
}

.order-review-item {
  border-bottom: 1px solid var(--stroke-primary-color);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.progress-timeline::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.progress-timeline {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.status-node {
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  display: inline-block;
  background-color: var(--stroke-secondary-color);
  flex-shrink: 0;
}

.status-node.active {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  border: 1px solid var(--accent-color);
  background-color: rgba(0, 155, 175, 0.1);
}

.status-connector {
  display: inline-block;
  width: 7rem;
  height: 0.0625rem;
  background: var(--stroke-secondary-color);
  flex-shrink: 0;
}

.status-connector.active {
  background: linear-gradient(90deg, var(--accent-color) -1.25%, #C1C8C9 100%);
}

.status-connector.done {
  background: var(--accent-color);
}

.stringer-card.active {
  background-color: #C5E5EB !important;
  border: solid 1px var(--accent-color);
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: black;
  display: inline-block;
  margin: 0.25rem
}

.filter-counter {
  color: white;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  background-color: #ADAFAF;
}

#footer {
  background-color: #0B1213;
}

.tooltip {
  --bs-tooltip-bg: var(--footer-color);
  --bs-tooltip-color: var(--body-text-color);
}

.modal-fullscreen {
  height: 100dvh;
}

.dropdown-toggle.is-invalid {
  border: solid 1px var(--error-color);
}

.dropdown-menu {
  padding: 0;
  border: none;
  --bs-dropdown-item-padding-y: 0.5rem;
}

.dropdown-menu > .dropdown-item:first-child {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.dropdown-menu > .dropdown-item:last-child {
  border-bottom-left-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.dropdown-menu > .dropdown-item:hover {
  background-color: #DEE5E6;
}

.dropdown-menu > .dropdown-item:active, .dropdown-menu > .dropdown-item.active {
  background-color: #C5E5EB;
  color: #07626F;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  --bs-text-opacity: 0.4;
}

.shadow-lg {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.10);
}

.shadow-sm {
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.08);
}

.position-sticky.bottom-0 {
  z-index: 10;
  background-color: var(--bs-body-bg);
}

#player-search .dropdown-item:first-child {
  padding-top: 1rem !important;
}

#player-search .dropdown-item:last-child {
  padding-bottom: 1rem !important;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.offcanvas-md {
  --bs-offcanvas-height: 90vh;
}

.progress-section {
  border-top: 0.2rem solid;
  border-radius: 0.2rem;
  transition: color 0.5s;
  color: #BABEBF;
  opacity: 1;
}

.progress-section.completed {
  color: #85B5BC;
}

#signup-form input {
  border: none;
  border-bottom: var(--bs-border-width) solid var(--bs-border-color);
  padding-left: 0;
  background-color: inherit;
  font-family: sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--bs-heading-color);
  border-radius: 0;
}

#signup-form input.is-invalid {
  border-color: var(--error-color);
}

#signup-form ::placeholder {
  color: #A6A6A6;
}

#signup-form input:focus {
  outline: none;
  box-shadow: none;
}